$mq-breakpoints: (
  mobile:  320px,
  tablet:  740px,
  desktop: 980px,
  wide:    1300px,

  // Tweakpoints
  middleBreakMin: 980px,
  middleBreakMax: 1023px
);

@import "~sass-mq";

$button-color: #58b7b1;

.asc-global-wrapper #asc-header-con .asc-header-promo {
  max-height: none;
}

#asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > .mar-header-promo-message,
#asc-header-con > .mar-header-main > .asc-header-promo {
  padding: 0;
}

#asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > #your-store-banner,
#asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > .mar-header-promo-message > #your-store-banner {
  display: flex;
  flex-flow: row nowrap;
  max-width: 31.5rem;
  margin: 0 auto 0 0;
  padding: 0;
}

@include mq($from: "mobile", $until: "desktop") {
  #asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > #your-store-banner,
  #asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > .mar-header-promo-message > #your-store-banner {
    margin: 0 auto;
    justify-content: center;
  }
  #asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > .mar-header-promo-message {
    background: #f6f8f8;
  }
}

@include mq($from: "middleBreakMin", $until: "middleBreakMax") {
  #asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > #your-store-banner,
  #asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > .mar-header-promo-message > #your-store-banner {
    padding: 0 2.2rem;
  }

  #asc-header-con > .mar-header-main > .asc-header-promo > .mar-grid-container > .mar-header-promo-message {
    background: #ffffff;
  }
}

#ys-img {
  padding-top: 0.7rem;
  margin-right: 0.4rem;
}

.ys-rows , .ys-rows p{
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.1rem;
  text-transform: none !important;

  div {
    margin: 0 0.1rem;
    line-height:1.2rem;
  }
}

#ys-row-1 {
  padding-top: 0.6rem;
  margin-bottom: 0.2rem;
}

#ys-store-label {
  display: none;
  white-space: pre;
}

#ys-text {
  font-family: 'HelveticaNeueW01-65Medi';
  text-transform: none !important;

& p {
  line-height: 1.2rem;
  margin: 0;
}
}

.ys-uline {
  color: black !important;
  text-decoration: underline !important;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: none;
}

#ys-open-dot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.red-dot {
  background-color: red !important;
}

.green-dot {
  background-color: $button-color !important;
}

#ys-open-hours {
  color: #000000;
}
